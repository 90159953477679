
import kebabCase from 'lodash.kebabcase'
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDETooltip from '@/components/pde/PDETooltip.vue'

export default {
  name: 'LeftNavItemBody',

  components: {
    PDEIcon,
    PDETooltip,
  },

  props: {
    isExpanded: {
      require: true,
      type: Boolean,
      default: false,
    },
    label: {
      require: true,
      type: String,
      default: 'missing label',
    },
    icon: {
      require: true,
      type: String,
      default: '',
    },
    route: {
      require: false,
      type: String,
      default: null,
    },
  },

  methods: {
    kebabCase,
  },
}
